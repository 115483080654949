import React from 'react'
import { RouteComponentProps, RouterProps, withRouter } from 'react-router-dom'
import ProductDetailsComponent from './ProductDetailsComponent'

interface ProductDetailsRouterComponentProps{
    lang?:string;
    space?:string;
    id?:string;
}

const ProductDetailsRouterComponent = ({match}:RouteComponentProps<ProductDetailsRouterComponentProps> ) => {
    return (
        <ProductDetailsComponent isFromRoute={true} lang={match.params.lang} space={match.params.space} id={match.params.id} />
    )
}

export default withRouter(ProductDetailsRouterComponent)
