import React, {useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import MainPageComponent from './components/MainPageComponent';
import NotFound from './components/notFound/NotFound';
import createHistory from "history/createBrowserHistory";
import ProductDetailsRouterComponent from './components/productDetails/ProductDetailsRouterComponent';
import i18next from 'i18next';
import { Utils } from './utils/Utils';
// CSS
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';

const App = () => {
  const createdHistory = createHistory();

  const changeLanguageHandler = (lang: string)=> {
    var splited = window.location.pathname.split("/").filter(o=> Utils.isStringNullOrEmpty(o)==false);
    if(splited){
      splited[0]= lang ?? "";
      var result  = splited.join("/");
      
      createdHistory.push("/"+ result );
      createdHistory.go(0);
    }
    i18next.changeLanguage(lang);
  }

  useEffect(() => {
    (function () {
      window.parent.addEventListener("message", function (event) {
        if (event.data.event === "setLanguage") {
          if (event.data.payload === "en" || event.data.payload === "ka") {

            if(event.data.payload){
              changeLanguageHandler(event.data.payload.toLowerCase())
            }


          } else {
            if(event.data.payload){
              changeLanguageHandler("en")
            }
          }
        }
      });
    })();
  }, []);

  return (
    <Router >
      <div className="App">
        <div className="wrapper">
          <Switch>

            <Route path="/" exact>
              <MainPageComponent />
            </Route>

            <Route exact path="/notfound">
              <NotFound />
            </Route>

            <Route exact path="/:lang/notfound">
              <NotFound />
            </Route>

            <Route exact path="/:lang/:space" >
              <MainPageComponent />
            </Route>

            <Route exact path="/:lang" >
              <MainPageComponent />
            </Route>

            <Route exact path="/:lang/product/:id" component={ProductDetailsRouterComponent} />
            
            <Route path="/:lang/:space/product/:id/" component={ProductDetailsRouterComponent} />
            
            {/* map url */}
            <Route path="/:lang/:space/map/:city" exact>
              <MainPageComponent />
            </Route>

            <Route path="/:lang/:space/map" exact>
              <MainPageComponent />
            </Route>

            <Route path="/:lang/map/:city" exact>
              <MainPageComponent />
            </Route>

            <Route path="/:lang/map" exact>
              <MainPageComponent />
            </Route>
            {/* end map urls */}

            <Route >
              <NotFound />
            </Route>
           
          </Switch>

        </div>
      </div>
    </Router>
  );
}

export default App;
