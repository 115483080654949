import React from 'react';
import { ICity } from '../../models/ICity';
import CityListItemComponent from '../cityListItem/CityListItemComponent';
import './CityListComponent.css';
import ScrollContainer from 'react-indiana-drag-scroll'
import { useTranslation } from 'react-i18next';

interface IProps {
    cities: ICity[],
    onItemClick: (city: ICity | undefined) => void;
}
export const CityListComponent:React.FC<IProps> = ({ cities, onItemClick }) => {
    const { t } = useTranslation();
    return (
        <div className="cities-list products-section-component ">
            <h2 className="cities-list-title f-lgv">{t('address')}</h2>
            <ScrollContainer className="scroller-margin-padding d-flex flex-nowrap">
                { cities!?.map((o, i) => <CityListItemComponent key={i} city={o} onClick={onItemClick}/>) }
            </ScrollContainer>
        </div>
    )
}

export default CityListComponent;