import React, { useEffect, useState } from 'react';
import LangButtonComponent from '../langButton/LangButtonComponent';
import { Link } from "react-router-dom";

// CSS
import './HeaderComponent.css';

// Images
import patternSrc from '../../assets/img/pattern.png'
import logoSrc from '../../assets/img/logo.svg'
import { News } from '../../models/News';
import { Language } from '../../models/Language';
import { useTranslation } from 'react-i18next';
import ApiManager from '../../data/ApiManager';
import { Utils } from '../../utils/Utils';

interface HeaderComponentsProps {
  newsClicked?: () => void;
  currentNews?: News | undefined;
  languageClicked?:()=>void;
  languages:Language[]
}

const HeaderComponent:React.FC<HeaderComponentsProps> = ({newsClicked, currentNews,languageClicked , languages}) => {

  const {t} = useTranslation();

  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    ApiManager.getInstance().getData<string>(`client/get-background-image`).catch(o=>{
    }).then(dataResult=>{	
       if(dataResult){
         setBackgroundImage(dataResult);
       }
    });	
  }, []);
  
  const showNewsPopup = () => {
    if (newsClicked) {
      newsClicked();
    }
  }

  return (
    <div className="header-component">
      <div className="bg">
        <span style={{ backgroundImage: "url(" + backgroundImage + ")" }}></span>
      </div>
      {!Utils.IsWebView() && (
        <div className="container">
          <div className="row no-gutters">
            <div className="col-4 d-flex align-items-start">
              <div className="logo">
                <img src={logoSrc} alt="" />
              </div>
            </div>
            <div className="col-4 d-flex align-items-start justify-content-center">
              {
                currentNews ? (<div className="news-btn f-lgv" onClick={showNewsPopup}>{currentNews.newsTitle}</div>) : <></>
              }
            </div>
            <div className="col-4 d-flex align-items-start justify-content-end">
              <LangButtonComponent languages={languages} languageClicked={languageClicked}/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeaderComponent;