import React, { useEffect, useState } from 'react';

// CSS
import './ShimmerComponent.css';

const ShimmerComponent = () => {
  
  return (
    <React.Fragment>
      <div className="container">
        <div className="shimmer-component">
          <div className="title shimmer-animation-el shimmer-animation-el-1"></div>
          <div className="items">
            <div className="item shimmer-animation-el shimmer-animation-el-2"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-3"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-4"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-5"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-6"></div>
          </div>
        </div>
        <div className="shimmer-component">
          <div className="title shimmer-animation-el shimmer-animation-el-7"></div>
          <div className="filter">
            <div className="item shimmer-animation-el shimmer-animation-el-8"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-9"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-10"></div>
          </div>
          <div className="items">
            <div className="item large shimmer-animation-el shimmer-animation-el-11"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-12"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-13"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-14"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-15"></div>
          </div>
        </div>
        <div className="shimmer-component">
          <div className="title shimmer-animation-el shimmer-animation-el-7"></div>
          <div className="filter">
            <div className="item shimmer-animation-el shimmer-animation-el-8"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-9"></div>
            <div className="item shimmer-animation-el shimmer-animation-el-10"></div>
          </div>
          <div className="items">
            <div className="item large shimmer-animation-el shimmer-animation-el-11"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-12"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-13"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-14"></div>
            <div className="item large shimmer-animation-el shimmer-animation-el-15"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShimmerComponent;