import coldIconSrc from "../assets/img/cold-icon.svg";
import hotIconSrc from "../assets/img/hot-icon.svg";
import { DrinkType } from "../models/DrinkType";

export abstract class Utils {
  public static GetHotOrColdIconByState(state: DrinkType): string {
    if (state == DrinkType.HOT) return hotIconSrc;
    else if (state == DrinkType.COLD) return coldIconSrc;

    return "";
  }

  public static isStringNullOrEmpty = (value: string) => {
    return !value || value == undefined || value == "" || value.length == 0;
  };

  public static ConstructUrl(routeParams: (string | undefined)[]): string {
    var url = "";

    routeParams.forEach((o) => {
      if (o) {
        if (!this.isStringNullOrEmpty(o)) {
          url += `/${o}`;
        }
      }
    });

    return url;
  }

  public static IsWebView(): boolean {
    const isWebView = localStorage.getItem('isWebView')
    
    return Boolean(isWebView) 
  }
}
