import React, { useEffect, useState } from 'react';

// CSS
import './ProductDetailShimmerComponent.css';

const ProductDetailShimmerComponent = () => {
  
  return (
    <React.Fragment>
      <div className="product-detail-shimmer-component">
        <div className="info-section">
          <div className="row">
            <div className="col-6">
              <div>
                <h2 className="f-lgv shimmer-animation-el shimmer-animation-el-1"></h2>
                <h2 className="f-lgv shimmer-animation-el shimmer-animation-el-2"></h2>
              </div>
              <div className="price shimmer-animation-el shimmer-animation-el-3"></div>
              <div className="specifications">
                <span className="shimmer-animation-el shimmer-animation-el-4"></span>
                <p className="shimmer-animation-el shimmer-animation-el-5"></p>
              </div>
            </div>
            <div className="col-6">
              <div className="imgs-cont d-flex align-items-center justify-content-center">
                <div className="bg shimmer-animation-el shimmer-animation-el-6"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="description">
          <div className="title shimmer-animation-el shimmer-animation-el-7"></div>
          <div className="text shimmer-animation-el shimmer-animation-el-8"></div>
          <div className="text shimmer-animation-el shimmer-animation-el-9"></div>
          <div className="text shimmer-animation-el shimmer-animation-el-10"></div>
          <div className="text shimmer-animation-el shimmer-animation-el-11"></div>
          <div className="text shimmer-animation-el shimmer-animation-el-12"></div>
          <div className="text shimmer-animation-el shimmer-animation-el-13"></div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductDetailShimmerComponent;