import React from 'react';
import { ICity } from '../../models/ICity';
import { useTranslation } from 'react-i18next';

import langIconSrc from '../../assets/img/list-map-pin.svg'

import './CityListItemComponent.css';

interface IProps {
    city: ICity | undefined;
    onClick: (city: ICity | undefined) => void;
}

export const CityListItemComponent: React.FC<IProps> = ({ city, onClick }) => {
    const { t } = useTranslation();

    return (
        <div className="city-item" onClick={() => onClick(city)}>
            <div className="city-item-icon">
                <img src={langIconSrc} />
            </div>

            <p className="city-item-title">{city?.name}</p>
            <p className="city-item-sub-title">{city?.pointCount} {t('location')}</p>
        </div>
    )
}

export default CityListItemComponent;