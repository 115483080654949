import React, { ComponentProps, useEffect, useState } from 'react';
import CapsulesComponent from '../capsules/CapsulesComponent';
// CSS
import './ProductDetailsComponent.css';

// Images
import backArrowSrc from '../../assets/img/back-arrow.svg'
import ProductImgBigSrc2 from '../../assets/img/product-img-big-4.png'
import ProductImgBigSrc3 from '../../assets/img/product-img-big-5.png'
import { Product } from '../../models/Product';
import { Language } from '../../models/Language';
import LangButtonComponent from '../langButton/LangButtonComponent';
import LangPopupComponent from '../langPopup/LangPopupComponent';
import { Utils } from '../../utils/Utils';
import { Redirect, useHistory } from 'react-router-dom';
import ProductDetailShimmerComponent from '../productDetailShimmer/ProductDetailShimmerComponent';
import ApiManager from '../../data/ApiManager';
import { useTranslation } from 'react-i18next';

interface ProductDetailsComponentProps{
  currentProduct?:Product,
  langauges?:Language[],
  backClicked?:()=>void,
}

interface ProductDetailsComponentProps{
  lang?:string;
  space?:string;
  id?:string;
  isFromRoute?:boolean;
}

const ProductDetailsComponent:React.FC<ProductDetailsComponentProps> = ({currentProduct,langauges , backClicked , lang,space,id ,isFromRoute }) => {

  const [imgSelectedIndexState, setImgSelectedIndexState] = useState(0)
  const history = useHistory();
  const [languagesState, setLanguagesState] = useState<Language[] | undefined>(langauges)
  const [isLoadingState, setIsLoadingState] = useState(false)
  const [currentProductState, setCurrentProductState] = useState<Product | undefined>(currentProduct)

  const {t} = useTranslation();

  const backArrowClicked =()=>{
    if(isFromRoute){
      var url = Utils.ConstructUrl([languagesState?.find(o=>o.isSelected)?.code?.toLowerCase(),space==undefined?"":space])
      history.push({
        pathname: url, 
        search: `${Utils.IsWebView() ? '?isWebView=true' : ""}`,
      });
    }

    if(backClicked){
      backClicked();
      return;
    }
  }
  useEffect(() => {
    if(isFromRoute){
      ApiManager.getInstance().getData<Language[]>("client/languages").then(dataResult=>{	
       
      if(dataResult){	
        var current = dataResult.find(o=>o.code.toLowerCase()==lang);
        if(current){
          current.isSelected=true;
        }
        setLanguagesState( dataResult);	
      }	
    });	
     
    }
    
  }, [])

  useEffect(() => {
    
    if(isFromRoute){
      setIsLoadingState(true);
      ApiManager.getInstance().getData<Product>(`client/${lang}/product/${id}`).then(dataResult=>{
        setCurrentProductState(dataResult);
        setIsLoadingState(false)
      })
    }
    
  }, [languagesState])

  // useEffect(() => {
    
  //   if(isFromRoute){
  //     setIsLoadingState(true);
  //     ApiManager.getInstance().getData<Product>(`client/${lang}/product/${id}`).then(dataResult=>{
  //       setCurrentProductState(dataResult);
  //       setIsLoadingState(false)
  //     })
  //   }
  //   else{
  //     setIsLoadingState(true);
  //     ApiManager.getInstance().getData<Product>(`client/${lang}/product/${currentProduct?.slug}`).then(dataResult=>{
  //       setCurrentProductState(dataResult);
  //       setIsLoadingState(false)
  //     })
  //   }
    
  // }, [languagesState])


  useEffect(() => {
    setTimeout(() => {
      document.getElementsByTagName('body')[0].classList.add("visible-product-detail");
      document.querySelector(".product-details-component")?.classList.add("visible");
    }, 10);
  }, [])

  const currentProductAllImages = ()=>{

    if(currentProductState?.otherImages){
      return currentProductState?.imgUrls.concat(currentProductState?.otherImages);
    }
    return currentProductState?.imgUrls ?? [];

  };

  return (

   

    <div className={"product-details-component"}>
      <div className="wrapper">
        <div className="container">

          <div className="top">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <div onClick={()=>backArrowClicked()} className="back-arrow">
                  <img src={backArrowSrc} alt=""/>
                </div>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end">
                {
                  languagesState && !Utils.IsWebView() ?(
                  <LangButtonComponent buttonStyle={"black"} languages={languagesState}/>):(<></>)
                }
              </div>
            </div>
          </div>

          {/* <ProductDetailShimmerComponent/> */}

          {
            isLoadingState? (<ProductDetailShimmerComponent />)
            :( <React.Fragment>
              <div className="info-section">
                <div className="row">
                  <div className="col-6">
                    <div className={currentProductState?.type == 'DEFAULT' ? 'type default' : 'type'}>
                      {

                        currentProductState? (
                          <div>
                            <img className="icon" src={Utils.GetHotOrColdIconByState(currentProductState?.type)} alt="" />
                            <span className={currentProductState?.type == 'COLD' ? 'cold' : 'hot'}>{t(currentProductState?.type)}</span>
                          </div>
                        ):<> </>

                        // currentProductState? (
                        //   <div>
                        //     <img className="icon" src={Utils.GetHotOrColdIconByState(currentProductState?.type)} alt="" />
                        //     <span className={currentProductState?.type == 'COLD' ? 'cold' : 'hot'}>{currentProductState?.type}</span>
                        //   </div>
                        // ):<> </>
                      }
                    </div>
                    <h2 className="f-lgv">{currentProductState?.name}</h2>
                    {
                      currentProductState?.price &&
                      <div className="price">{currentProductState?.price}&#8382;</div>
                    }
                    <div className="specifications">
                      {
                        currentProductState?.specifications?.map((o, index)=>(
                        <React.Fragment key={index}>
                              <span>{o.name}</span>
                              <p>{o.value}</p>
                        </React.Fragment>))
                      }
                    
                    </div>
                    {
                      currentProductState? (
                        currentProductAllImages()!.length >= 2 ? (
                          <div className="small-imgs d-flex">
                            {
                              currentProductAllImages()!.map((o,i)=>(
                                console.log(currentProductState),
                                <div key={i} onClick={()=>{
                                  setImgSelectedIndexState(i);
                                }} className={"img d-flex align-items-center justify-content-center "+(i==imgSelectedIndexState ?"active":"")} >
                                  <img src={o} alt="" /> 
                                </div>
                              ))
                            }
                          </div>
                        ):(
                          <></>
                        )
                      ):<></>
                    }
                  </div>
                  <div className="col-6">
                    <div className="imgs-cont d-flex align-items-center justify-content-center">
                      <div className="bg" style={{ backgroundColor: currentProductState?.bgColor! }}></div>
                      <div className="img-box">
                        <div className="img">
                          {
                          currentProductAllImages()!.map((o,i)=>(
                              <img key={i} className={i==imgSelectedIndexState? "active":""} src={o} /> 
                          ))
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
              <div className="description">
                <div className="title">{currentProductState?.title}</div>
                <div className="text" dangerouslySetInnerHTML={{__html:currentProductState?.description!}}></div>
              </div>
    
              <div className="bottom">
                {
                  currentProductState?.shortDescription! ? <div className="border-line"></div> : <></>
                }
                
    
                <div className="small-description">
                  <div className="text" dangerouslySetInnerHTML={{__html:currentProductState?.shortDescription!}}></div>
                </div>
    
                <CapsulesComponent capsuleProducts={currentProductState?.capsuleProducts==null? undefined : currentProductState.capsuleProducts}/>
              </div>
              </React.Fragment>)
          }
         
        </div>
      </div>
    </div> 
  );
}

export default ProductDetailsComponent;