import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ApiManager from '../../data/ApiManager';
import { Socials } from '../../models/Socials';

// CSS
import './FooterComponent.css';

interface ISoaicals {
  imageUrl: string,
  link: string
}

interface IContactInfo {
  name: string,
  value: string,
  socialLinks: Socials[]
}

interface IFooterProps {
  language: string
}

const FooterComponent:React.FC<IFooterProps> = ({language}) => {
  
  const [social, setSocial] = useState<IContactInfo>();

  useEffect(() => {
    ApiManager.getInstance().getData<IContactInfo>(`client/${language}/contact-info`).then(dataResult=>{	
      setSocial(dataResult)
    });	
  }, [language]);

  return (
    <div className="footer-component">
      <div className="container">
        <div className="inner">
          <div className="row no-gutters">
            <div className="col-6 d-flex align-items-end">
              <div>
                <p>{social?.name}</p>
                <a className="phone f-bold" href={"tel:" + social?.value}>{social?.value}</a>
              </div>
            </div>
            <div className="col-6 d-flex align-items-end justify-content-end">
              <div className="socials">
                {social?.socialLinks.map((o, index) => {
                  return(
                    <a key={index} href={o.link} target="_blank">
                      <img src={o.imageUrl} />
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;