import Axios, { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import i18next from "i18next";

class ApiManager {
  private static instance: ApiManager;
  //real url
  private static mainUrl: string = "https://cms.meamacollect.ge/meama-collect/api/";
  //test url
  // private static mainUrl: string = "https://vending-test2.meama.ge/meama-collect/api/";
  
  private constructor() {}

  public static getInstance(): ApiManager {
    if (!ApiManager.instance) {
      ApiManager.instance = new ApiManager();

      Axios.defaults.baseURL = `${ApiManager.mainUrl}`;
    }

    return ApiManager.instance;
  }

  public getMainUrl = () => {
    return ApiManager.mainUrl;
  };

  public async getData<T>(resource: string): Promise<T | undefined> {
    try {
      var response = await Axios.get(resource);
      return response.data;
    } catch (ex) {
      if (ex.response?.status == 404) {
        if (i18next.language) {
          window.location.href = `/${i18next.language}/notfound`;
          return;
        }

        window.location.href = "/notfound";
      }
    }
  }
}

export default ApiManager;
