import React, { useState } from 'react';

// CSS
import './CapsuleComponent.css';

// Images
import capsuleImgSrc1 from '../../assets/img/capsule-img-1.png'
import { CapsuleProduct } from '../../models/CapsuleProduct';

interface CapsuleComponentProps{
  capsuleProduct:CapsuleProduct
}
const CapsuleComponent :React.FC<CapsuleComponentProps>= ({capsuleProduct}) => {

  return (
    <div className="capsule-item">
      <div className="d-flex align-items-center">
        <div className="img d-flex align-items-center justify-content-center">
          <div className="bg" style={{ backgroundColor:capsuleProduct.bgColor }}></div>
          <img src={capsuleProduct.imgUrl} alt="" />
        </div>
        <div className="info">
          <div className="capsule-number f-lgv">{capsuleProduct.name}</div>
          {
            capsuleProduct.specifications?.map((o, index)=>{
              return (
                <div className="specification" key={index}>
                  <div className="d-flex align-items-center">
                    <p className="mr-auto">{o.name}</p>
                    <div className="line"><div style={{ width: (o.value/o.maxValue)*100 }}></div></div>
                    <div className="value">{`${o.value} / ${o.maxValue}`}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default CapsuleComponent;