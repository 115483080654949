import React from 'react';
import './PointCardOnMap.css';
import langIconSrc from '../../assets/img/list-map-pin.svg'
import externalLinkIcon from '../../assets/img/external-link.svg'
import { IPoint } from '../../models/IPoint';
import { useTranslation } from 'react-i18next';

interface IProps {
    point: IPoint;
}

const PointCardOnMap: React.FC<IProps> = ({ point }) => {
    const { t } = useTranslation();

    return (
        <div className="point-item-on-map">
            <img className="point-image" src={point!?.imageUrl!} />
            
            <div className="card-content">
                <p className="card-city-name">{point!?.pointName!}</p>
                <p className="card-route-text">{point!?.address!}</p>
                
                <div className="direction-box">
                    <div className="point-working-time">
                        <p className="time">{point!?.openTime!} - {point!?.closeTime!}</p>
                        <span className="line"></span>
                        <p className="status" style={point!?.open! ? { color: '#41BD64' } : undefined}>{point!?.open! ? t('open') : t('closed')}</p>
                    </div>

                    <div className="direction-container">
                        <div>
                            {
                                point!?.distance !== "" ?
                                <div className="road-length-box">
                                    <div className="icon-box">
                                        <img src={langIconSrc} />
                                    </div>
                                    <p className="text">{point!?.distance! ?? ""}</p>
                                </div>
                                : <div className="road-length-box"> </div>
                            }
                        </div>
                        
                        <a href={point.googleMapLink} className="direction-btn">
                            <p>{t('direction')}</p>
                            <img src={externalLinkIcon} />
                        </a>
                    </div>
                </div>
            </div>
    
        </div>
    );
}

export default PointCardOnMap;