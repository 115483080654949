import React, { useState, useEffect } from 'react';
import './LocationListComponent.css';
import backArrowSrc from '../../assets/img/back-arrow.svg'
import LangButtonComponent from '../langButton/LangButtonComponent';
import externalLinkIcon from '../../assets/img/external-link.svg'
import { Language } from '../../models/Language';
import langIconSrc from '../../assets/img/list-map-pin.svg'
import mapIco from '../../assets/img/map.svg';
import { ICity } from '../../models/ICity';
import { IPoint } from '../../models/IPoint';
import { useTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll'

interface IProps {
    closeMapClick: () => void;
    languages: Language[];

    cities: ICity[];
    points: IPoint[];

    selectedCity: ICity | undefined,
    onClick: (city: ICity | undefined) => void,
}

export const LocationListComponent: React.FC<IProps>= ({ closeMapClick, languages, cities, points, selectedCity, onClick }) => {
    const { t } = useTranslation();
    const [selectedCityName, setSelectedCityName] = useState(selectedCity?.name ?? '');

    const onCityChange = (city: ICity | undefined) => {
        // remove active class
        var elem = document.getElementById('points-list-container');
        elem?.classList.remove('active');
        onClick(city)
        
        //select clicked city
        setTimeout(() => {
            if(city) setSelectedCityName(city.name);
            else setSelectedCityName('');
            elem?.classList.add('active')
        }, 500);
    }

    return (
        <div className="location-list">
            <div className="map-container-header">
                <div onClick={closeMapClick} className="back-arrow">
                    <img src={backArrowSrc} alt=""/>
                </div>
                <div className="language-btn">
                    { <LangButtonComponent buttonStyle={"black"} languages={languages}/> }
                </div>
            </div>

            <div className="scroller-points-list">
                <p className="title f-lgv">{t('address')}</p>
                <div className="filter-container">

                    <ScrollContainer className="scroller d-flex flex-nowrap">
                        <div className={`filter-container-item ${selectedCity === undefined ? 'active' : ''}`}  onClick={() => onCityChange(undefined)}>
                            <p>{t('all')}</p>
                        </div>
                        {
                            cities!?.map(o => {
                                return (
                                    <div className={`filter-container-item ${o!?.name! === selectedCity?.name! ? 'active' : ''}`}  onClick={() => onCityChange(o)}>
                                        <p>{o.name}</p>
                                    </div>
                                )
                            })
                        }
                    </ScrollContainer>

                </div>


                <div className='point-item-container active' id='points-list-container'>
                    {
                        points!?.map(o => {
                            return (
                                o.cityName === selectedCityName || selectedCityName === '' ?
                                <div className='point-item'>
                                    <img className='item-image' src={o.imageUrl} />
                                
                                    <div className="point-item-content">
                                        <div className="point-item-content-left">
                                            <div className="text-container">
                                                <p className="item-city-title">{o.pointName}</p>
                                                <p className="item-city-sub-title">{o.address}</p>
                                            </div>
                
                                            <a href={o.googleMapLink} className="direction-btn">
                                                <p>{t('direction')}</p>
                                                <img src={externalLinkIcon} />
                                            </a>
                
                                            <div className="point-working-time">
                                                <p className="time">{o.openTime} - {o.closeTime}</p>
                                                <span className="line"></span>
                                                <p className="status" style={o.open ? { color: '#41BD64' } : undefined}>{o!?.open! ? t('open') : t('closed')}</p>
                
                                                
                                                {
                                                    o.distance ?
                                                    <div className="road-length-box">
                                                        <div className="icon-box">
                                                            <img src={langIconSrc} />
                                                        </div>
                                                        <p className="text">{o.distance}</p>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                            )
                        })
                    }

                    
                    <div className="list-button" onClick={closeMapClick}>
                        <img src={mapIco} />
                        <p>{t('map')}</p>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default LocationListComponent;