import React, { useEffect, useState } from 'react';
import './CityFilterPopupComponent.css';
import langIconSrc from '../../assets/img/list-map-pin.svg'
import checkmarkIconSrc from '../../assets/img/checkmark-icon.svg';
import { ICity } from '../../models/ICity';
import { useTranslation } from 'react-i18next';

interface IProps {
    cities: ICity[],
    selectedCity: ICity | undefined,
    onClick: (city: ICity | undefined) => void,
    onClose: () => void,
    showFilterPopup: boolean
}

export const CityFilterPopupComponent: React.FC<IProps> = ({ cities, showFilterPopup, selectedCity, onClick, onClose }) => {
    const [showClasses, setShowClasses] = useState("hidden");
    const { t } = useTranslation();

    useEffect(() => {
        if(showFilterPopup) {
            setShowClasses("show")
            setTimeout(() => setShowClasses("show bck-color"), 500)
        } else {
            if(showClasses !== 'hidden')
            setShowClasses("show")
            setTimeout(() => setShowClasses("hidden"), 300)
        }
    }, [showFilterPopup])

    return (
        <div className={`city-filter-popup ${showClasses}`} onClick={onClose}>
            <div className="popup-content">
                <p className="f-lgv title">{t('locationa')}</p>


                <div className="city-container">
                    <div className="city-container-item" onClick={() => onClick(undefined)}>
                        <div className="location-ico-box">
                            <img src={langIconSrc} />
                        </div>
                        <p className="city-text">{t('all')}</p>
                        <div className={`checkmark-ico-box ${selectedCity === undefined ? 'active' : ''}`}>
                            <img src={checkmarkIconSrc} />
                        </div>
                    </div>
                    { 
                        cities!?.map(o => {
                            return (
                                <div className="city-container-item" onClick={() => onClick(o)}>
                                    <div className="location-ico-box">
                                        <img src={langIconSrc} />
                                    </div>
                                    <p className="city-text">{o.name}</p>
                                    <div className={`checkmark-ico-box ${o.id === selectedCity!?.id! ? 'active' : ''}`}>
                                        <img src={checkmarkIconSrc} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CityFilterPopupComponent;