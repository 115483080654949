import React from 'react';
import { CategoryType } from '../../models/CategoryType';
import { Product } from '../../models/Product';

// Components
import ProductItemLargeComponent from '../productItemLarge/ProductItemLargeComponent';
import ProductItemMediumComponent from '../productItemMedium/ProductItemMediumComponent';
import ProductItemSmallComponent from '../productItemSmall/ProductItemSmallComponent';

interface ProductItemComponentProps{
  product: Product,
  categoryType: CategoryType,
  productClicked?:(product:Product)=>void;
}

const ProductItemComponent:React.FC<ProductItemComponentProps> = ({product,categoryType , productClicked}) => {

  const getConcreteProductByObject = () => {

    if(categoryType == CategoryType.MEDIUM ){
      return <ProductItemMediumComponent productItemClicked={productClicked} product={product} />
    }
    else if (categoryType== CategoryType.LARGE){
      return <ProductItemLargeComponent productItemClicked={productClicked} product={product} />
    }
    return <ProductItemSmallComponent productItemClicked={productClicked} product={product} />
  }

  return getConcreteProductByObject();
}

export default ProductItemComponent;