import React from 'react';
import { Link } from "react-router-dom";

import { Product } from '../../models/Product';
import { Utils } from '../../utils/Utils';

// CSS
import './ProductItemMediumComponent.css';

interface ProductItemMediumComponentProps{
  product: Product ;
  productItemClicked?:(product:Product)=>void;
}

const ProductItemMediumComponent:React.FC<ProductItemMediumComponentProps> = ({ product ,productItemClicked }) => {
  
  return (

    <div onClick={()=>{ if(productItemClicked)productItemClicked(product)}} className={"product-item-medium-component product-item-component show"+" prodItem-"+product.id}>
      <div className="inner ">
        <div className="bg" style={{ backgroundColor: product.bgColor?.toString() }}></div>
        <img className="icon" src={Utils.GetHotOrColdIconByState(product.type)} alt="" />
        <div className="img-box">
          <div className="img">
            <img src={product.mainPhoto} alt="" />
          </div>
        </div>
        <div className="info">
          <p>{product.name}</p>
          {
            product.price &&
            <div className="price f-bold">{product.price}&#8382;</div>
          }
        </div> 
      </div>
    </div>
    
  );

}

export default ProductItemMediumComponent;