import React, { useEffect, useState } from 'react';
import { CapsuleProduct } from '../../models/CapsuleProduct';
import CapsuleComponent from '../capsule/CapsuleComponent';

// CSS
import './CapsulesComponent.css';
interface CapsulesComponentProps{
  capsuleProducts?:CapsuleProduct[]
}
const CapsulesComponent :React.FC<CapsulesComponentProps>= ({capsuleProducts}) => {


  useEffect(() => {
    
    
  }, [])
  
  if(capsuleProducts==undefined || capsuleProducts==null || capsuleProducts?.length==0){
    return <></>
  }

  return (

    <div className="capsules-box">
      {
        capsuleProducts.map((o, index) => (<CapsuleComponent capsuleProduct={o} key={index} />))
      }
     
    </div>
  );
}

export default CapsulesComponent;