import React, { useRef, useState, useEffect } from "react";
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker,
  } from "react-google-maps";

import './GoogleMapComponent.css';
import listIco from '../../assets/img/list.svg'
import filterIco from '../../assets/img/filter.svg'
import locationIco from '../../assets/img/location.svg'
import locatIco from '../../assets/img/locat.svg'

import blackPin from '../../assets/img/black-map-pin.svg'
import brownPin from '../../assets/img/brown-map-pin.svg'
import backArrowSrc from '../../assets/img/back-arrow.svg'
import '../productDetails/ProductDetailsComponent.css';
import LangButtonComponent from '../langButton/LangButtonComponent';
import { Language } from "../../models/Language";
import { IPoint } from "../../models/IPoint";

import { Swiper, SwiperSlide } from "swiper/react";
import PointCardOnMap from '../mapPointcard/PointCardOnMap';
import CityFilterPopupComponent from '../cityFilterPopup/CityFilterPopupComponent';
import LocationListComponent from '../locationList/LocationListComponent';
import { useTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Pagination
} from 'swiper/core';
import { ICity } from "../../models/ICity";
import { Utils } from "../../utils/Utils";

// install Swiper modules
SwiperCore.use([Pagination]);

const Map = withScriptjs(withGoogleMap((props) => {
  const refMap = useRef(null);
  
  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={(props as any)!?.mapZoom}
      key={(props as any)!?.currentItemIndex! + (props as any)!?.selectedCity!?.name!}
      center={(props as any).mapCenter}
      options={{
        gestureHandling: "greedy",
        disableDefaultUI: true,
      }}
    >
      { (props as any).points.map((point: IPoint, index: number) => <Marker onClick={() => {
        (props as any).setPinIndexAndMapCenter(index, parseFloat(point.latitude), parseFloat(point.longitude))
      }} key={index+1} icon={{ url: (props as any).currentItemIndex === index ? brownPin : blackPin }} position={{ lat: parseFloat(point.latitude), lng: parseFloat(point.longitude) }} />) }
      <Marker icon={locatIco} key={0} position={(props as any).userLocaton} />
    </GoogleMap>
  );
}))
  
interface IProps {
  languages: Language[],
  closeMapClick: () => void;
  points: IPoint[],
  userLocaton: { lat: null | number, lng: null | number },
  selectCityFromMap: (city: ICity | undefined) => void,
  cities: ICity[],
  selectedCity: ICity | undefined,
  showMapPage: boolean,
}

export const GoogleMapComponent: React.FC<IProps> = ({ languages, closeMapClick, points, userLocaton, cities, selectCityFromMap, selectedCity, showMapPage }) => {
  const { t } = useTranslation();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [mapCenter, setMapCenter] = useState<{ lat: null | number, lng: null | number }>({ lat: null, lng: null });
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showListPage, setShowListPage] = useState(false);
  const [localPoints, setLocalPoints] = useState<IPoint[]>([]);
  const [swiperState, setSwiperState] = useState<any>();
  const slideTo = (index: number) => swiperState && swiperState.slideTo(index, 500);

  const mapZoomController = () => {
    if(selectedCity === undefined) return 7;
    else if(localPoints.filter(o => o.cityName === selectedCity!?.name!).length === 1) return 20;
    else return 10;
  }

  const setPinIndexAndMapCenter = (index: number, lat: number, lng: number) => {
    setMapCenter({ lat: lat, lng: lng });
    setCurrentItemIndex(index);
    slideTo(index);
  }

  useEffect(() => {
    if(points) {
      var pointsForLocalList = points.filter(o => o.cityName === selectedCity!?.name || selectedCity === undefined);
      setLocalPoints(pointsForLocalList);
    }
  }, [points, selectedCity])

  useEffect(() => {
    if(points)
    if(points[currentItemIndex])
    setMapCenter({ lat: parseFloat(localPoints![currentItemIndex]!?.latitude!), lng: parseFloat(localPoints![currentItemIndex]!?.longitude!) });
  }, [currentItemIndex, localPoints[currentItemIndex], selectedCity])
  
  //map wrapper for tsx
  const GetMap = (Map: React.ComponentClass<any>) => {
    return <Map
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyApbd_4uEVjzrsp5s52_iVVnGOGcewMJUM`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{zIndex: -1, height: '100%', position: 'relative', overflow: 'hidden'}}> </div>}
      mapElement={<div style={{ height: `100%` }} />}
      gestureHandling='greedy'
      points={localPoints}
      mapCenter={mapCenter}
      currentItemIndex={currentItemIndex}
      selectedCity={selectedCity}
      userLocaton={userLocaton}
      mapZoom={mapZoomController()}
      setPinIndexAndMapCenter={setPinIndexAndMapCenter}
    />
  }

  const slidesPerViewEnum: number | "auto" | undefined = "auto"
  const swiper = {
    slidesPerView: slidesPerViewEnum,
    centeredSlides: true,
    spaceBetween: 13,
    grabCursor: true,
    onSwiper: (swiper: any) => setSwiperState(swiper),
    onSlideChange: (swiper: any) => setCurrentItemIndex(swiper.activeIndex),
  };

  const goToUserLocation = () => setMapCenter(userLocaton);

  useEffect(() => {
    if(showMapPage) {
      setTimeout(() => {
        document.getElementsByTagName('body')[0].classList.add("visible-product-detail");
      }, 10);

      setTimeout(() => {
        var gmapDoc = document.getElementsByClassName('google-map-container')[0];
        if(gmapDoc)
        gmapDoc.classList.add('visible');
      }, 500);
  }
  }, [selectedCity, showMapPage])

  const cardClick = (index: number) =>{
    if(points)
    if(points[index])
    setMapCenter({ lat: parseFloat(localPoints![index]!?.latitude!), lng: parseFloat(localPoints![currentItemIndex]!?.longitude!) });

    slideTo(index);
  }

  return (
    <div className={`google-map-container wrapper`}>
      {
        !showListPage ?
        <div className="map-container-header">
          <div onClick={closeMapClick} className="back-arrow">
            <img src={backArrowSrc} alt=""/>
          </div>
          {
            !Utils.IsWebView() && (
              <div className="language-btn">
                <LangButtonComponent buttonStyle={"black"} languages={languages}/> 
              </div>
            )
          }
        </div> : null
      }

      { GetMap(Map as any) }

      <div className="swiper-section">
        <div className="right-buttons-container">
          <div className="location-btn" onClick={goToUserLocation}>
            <img src={locationIco} />
          </div>
          <div className="filter-btn" onClick={() => setShowFilterPopup(true)}>
            <img src={filterIco} />
          </div>
        </div>
        <div className="list-button" onClick={() => setShowListPage(true)}>
          <img src={listIco} />
          <p>{t('list')}</p>
        </div>
        <Swiper {...swiper}>
          {
            localPoints.map((o, i) => {
              return (
                <SwiperSlide onClick={() => cardClick(i)} key={i} style={i=== currentItemIndex ? {boxShadow: '0px 0px 11px #00000029'} : undefined}>
                  <PointCardOnMap point={o} />
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>

      <CityFilterPopupComponent showFilterPopup={showFilterPopup} onClose={() => setShowFilterPopup(false)} cities={cities} selectedCity={selectedCity} onClick={selectCityFromMap}/>
      { showListPage && <LocationListComponent selectedCity={selectedCity} onClick={selectCityFromMap} cities={cities} points={points} closeMapClick={() => setShowListPage(false)} languages={languages} />}
    </div>
  )
}

export default GoogleMapComponent;