import React, { useEffect, useState } from 'react';
import { Language } from '../../models/Language';
import { useTranslation } from 'react-i18next';

// Components
import LangItemComponent from '../langItem/LangItemComponent';

// CSS
import './LangPopupComponent.css';

interface LangPopupComponentProps{
  languages:Language[]
  outsideClick?:()=>void;
}

const LangPopupComponent:React.FC<LangPopupComponentProps> = ({languages,outsideClick}) => {

  const {t} = useTranslation();

  useEffect(() => {

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    
    setTimeout(() => {
      document.querySelector(".lang-popup-component")?.classList.add("visible");
      document.getElementsByTagName('body')[0].classList.add("disable-scroll");
    }, 10);

    return () => {
      
    }
  }, [])
  
  return (
    <React.Fragment>
      <div  className="lang-popup-component">
        <div onClick={()=>{
          if(outsideClick){
            outsideClick();
          }
        }} className="dark-bg"></div>
        <div  className="inner">
          <div className="wrapper">
            <div className="container">
              <h2 className="f-lgv">{t("langPopupText")}</h2>
              <div className="lang-items">
                {
                  languages?.map(o=>(<LangItemComponent language={o}/>))
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LangPopupComponent;