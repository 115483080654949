import React from 'react';
import { ProductCategory } from '../../models/ProductCategory';

// CSS
import './ProductsHeadlineComponent.css';

interface ProductsHeadlineProps{
  productCategory: ProductCategory,
  categoryClicked?:(category:ProductCategory) => void
}

const ProductsHeadlineComponent:React.FC<ProductsHeadlineProps> = ({productCategory,categoryClicked}) => {

  const subCategoryClicked = (productCategory:ProductCategory)=>{
    
    if(categoryClicked){
      categoryClicked(productCategory);
    }
  };

  return (
    <div className="products-headline-component">
      <h2 className="f-lgv">{productCategory.name}</h2>
      {
        productCategory.subCategories ? 
        (

          <div className="filter">
              {productCategory?.subCategories!.map(t=>(
                <div key={t.id} onClick={(e)=>subCategoryClicked(t)} className={t.isSelected? "item active": "item"}>{t.name}</div>
              ))}
          </div>
        ): (<></>)
      }
     
    </div>
  );
}

export default ProductsHeadlineComponent;