import React from 'react';
import { Link } from "react-router-dom";

import { Product } from '../../models/Product';

// CSS
import './ProductItemSmallComponent.css';

interface ProductItemSmallComponentProps{
  product: Product;
  productItemClicked?:(product:Product)=>void;
}

const ProductItemSmallComponent:React.FC<ProductItemSmallComponentProps> = ({ product , productItemClicked }) => {

  return (
    <div onClick={()=>{ if(productItemClicked)productItemClicked(product)}} className={"product-item-small-component product-item-component show"+" prodItem-"+product.id}>
      <div className="inner">
        <div className="img-box">
          <div className="img">
            <img src={product.mainPhoto} alt="" />
          </div>
        </div>
        <div className="info">
          <p>{product.name}</p>
          {
            product.price &&
            <div className="price f-bold">{product.price}&#8382;</div>
          }
        </div> 
      </div>
    </div>
  );
}

export default ProductItemSmallComponent;