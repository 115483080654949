import React, { useEffect, useState   } from 'react';
import {  RouteComponentProps, withRouter} from 'react-router-dom';
// Imagers
import notFoundImgSrc from '../../assets/img/not-found.svg'

// CSS
import './NotFound.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18next from 'i18next';

interface IRouteProps{
  lang?:string;
}

const NotFound =  ({ match }: RouteComponentProps<IRouteProps>) => {
  
  const {t} = useTranslation();

  useEffect(() => {

    if(match?.params?.lang){
        i18next.changeLanguage(match?.params?.lang,(d:any)=>{});
    }
   
  }, [])

  return (
    <React.Fragment>
      <div className="not-found">
        <div className="container">
          <div className="bg">
            <img src={notFoundImgSrc} alt="" />
          </div>
          <h2 className="f-lgv">{t("notFoundTitle")}</h2>
          <p>{t("pageNotFound")}</p>
          <Link to={"/"} className="button">{t("notFoundButtonTitle")}</Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(NotFound);