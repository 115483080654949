import React, { useEffect, useState } from 'react';

// CSS
import './NewsPopupComponent.css';

// Images
import newsImgSrc from '../../assets/img/news.png'
import { News } from '../../models/News';
import { useTranslation } from 'react-i18next';

interface MainPageComponentsProps {
  closedClick?: () => void;
  news: News;
}

const NewsPopupComponent:React.FC<MainPageComponentsProps> = ({closedClick, news}) => {

  const {t} = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".news-popup-component")?.classList.add("visible");
      document.getElementsByTagName('body')[0].classList.add("disable-scroll");
    }, 10);

  }, [])
  
  return (
    <React.Fragment>
      <div  className="news-popup-component">
        <div  className="dark-bg" onClick={()=>{
                      if(closedClick){
                        closedClick();
                      }
                    }}></div>
        <div  className="inner popupInner">
          <div className="wrapper">
            <div className="container">
              <div className="top">
                <div className="row no-gutters">
                  <div className="col-2"></div>
                  <div className="col-8 d-flex align-items-center justify-content-center">
                    <p>{news.newsTitle}</p>
                    
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-end">
                    <div className="close-btn" onClick={()=>{
                      if(closedClick){
                        closedClick();
                      }
                    }}><span></span></div>
                  </div>
                </div>
              </div>
              <div className="wrap">
                <img src={news.imageUrl} alt="" />
                <h2 className="f-lgv">{news.title}</h2>
                <div dangerouslySetInnerHTML={{__html:news.description}} className="text"></div>
                <div className="button" onClick={()=>{
                  if(closedClick){
                    closedClick();
                  }
                }}>{news.buttonText}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NewsPopupComponent;