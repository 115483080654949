import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPointInfo } from '../../models/IPointInfo';
import './PointInfoComponent.css';

interface IProps {
    pointInfo: IPointInfo,
    onClick: () => void,
    showPopup: boolean
}

export const PointInfoComponent: React.FC<IProps>= ({ pointInfo, onClick, showPopup }) => {
    const { t } = useTranslation();

    useEffect(() => {
        var doc = document.getElementsByClassName('point-info-popup')[0];
        
        setTimeout(() => {
            if(showPopup) doc.classList.add('show-popup')
        }, 500)
    }, [showPopup])

    const closePopup = () => {
        var doc = document.getElementsByClassName('point-info-popup')[0];
        doc.classList.remove('show-popup')
        setTimeout(() => {
            onClick();
        }, 500)
    }

    return (
        <div className={`point-info-popup`} onClick={closePopup}>
            <div className="point-info-popup-dark-bg"></div>
            <div className="point-info-popup-content">
                <div className="point-info-popup-content-center wrapper">

                    <p className="point-info-popup-title f-lgv">{pointInfo.closeDescriptionHeader}</p>

                    <p className="point-info-popup-text">{pointInfo.closeDescriptionText} <br /> <span className="point-info-popup-open-close-time">{pointInfo!?.openTime!}-{t('from')} {pointInfo!?.closeTime!}-{t('to')}.</span></p>

                    <div className="point-info-popup-button" onClick={closePopup}>
                        <p className="point-info-popup-btn-text ">{t('understood')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PointInfoComponent;