import React, { useEffect, useState } from 'react';

// CSS
import './LangButtonComponent.css';

// Images
import langIconSrc from '../../assets/img/lang-icon.svg'
import langIconBlackSrc from '../../assets/img/lang-icon-black.svg'
import arrowDownSrc from '../../assets/img/arrow-down.svg'
import arrowDownBlackSrc from '../../assets/img/arrow-down-black.svg'
import LangPopupComponent from '../langPopup/LangPopupComponent';
import { Language } from '../../models/Language';
import { Utils } from '../../utils/Utils';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';

interface LangButtonProps {
  languageClicked?: () => void;
  languages: Language[];
  buttonStyle?:"black"|"white"
  
}

const LangButtonComponent:React.FC<LangButtonProps> = ({languageClicked , languages,buttonStyle="white"}) => {

  const [shouldShowLanguagePopup, setShouldShowLanguagePopup] = useState(false)
  
  const history = useHistory();

  useEffect(() => {
    
    i18next.changeLanguage(languages?.find(o=>o.isSelected==true)?.code??"",(d:any)=>{
      
    });
    
  }, [languages])

  const languageButtonClicked = () => {
    // if (languageClicked) {
    //   languageClicked();
    // }
    if(languages.length<2){
      return;
    }
    
    if(languages.length==2){
      var languageToSelect = languages.find(o=>o.isSelected==false || o.isSelected==undefined);
      
      if(languageToSelect){
        var splited = window.location.pathname.split("/").filter(o=>Utils.isStringNullOrEmpty(o)==false);
        if(splited){
          splited[0]=languageToSelect?.code.toLowerCase() ?? "";
          var result  = splited.join("/");
          
          history.push("/"+result);
          history.go(0);
        }
      }

    }

    if(languages.length>2){
      setShouldShowLanguagePopup(true);
    }

  }

  const handleClickOutsideLang = () => {
    document.querySelector(".lang-popup-component")?.classList.remove("visible");
    document.getElementsByTagName('body')[0].classList.remove("disable-scroll");
    setTimeout(() => {
      setShouldShowLanguagePopup(false);
    }, 800);

  }
  
  return (

    <div className="lang-button d-flex align-items-center" onClick={languageButtonClicked}>
      <img  src={buttonStyle=="white"? langIconSrc:langIconBlackSrc} alt="" />
      {
        languages?.length > 2 ? (
          <span>{languages.find(o=>o.isSelected)?.name?.slice(0,2)}</span>
        ):(
          <span>{languages.find(o=>!o.isSelected)?.name?.slice(0,2)}</span>
        )
      }
      
      {
        languages?.length > 2 ? (<img className="arrow" src={buttonStyle=="white"? arrowDownSrc:arrowDownBlackSrc} alt="" />):(<></>)
      }

      {
        shouldShowLanguagePopup?(<LangPopupComponent outsideClick={handleClickOutsideLang} languages={languages} />):(<></>)
      }
    </div>
  );
}

export default LangButtonComponent;