import React, { useEffect, useState } from 'react';
import ApiManager from '../data/ApiManager';
import { ProductCategory } from '../models/ProductCategory';
import FooterComponent from './footer/FooterComponent';
import HeaderComponent from './header/HeaderComponent';
import ProductsSectionComponent from './productsSection/ProductsSectionComponent';
import ShimmerComponent from './shimmer/ShimmerComponent';
import LangPopupComponent from './langPopup/LangPopupComponent';
import NewsPopupComponent from './newsPopup/NewsPopupComponent';
import { News } from '../models/News';
import { match, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Product } from '../models/Product';
import { Language } from '../models/Language';
import ProductDetailsComponent from './productDetails/ProductDetailsComponent';
import { Utils } from '../utils/Utils';
import createHistory from "history/createBrowserHistory";
import i18next from "i18next";
import { ICity } from '../models/ICity';
import CityListComponent from './cityList/CityListComponent';
import GoogleMapComponent from './GMap/GoogleMapComponent';
import { IPoint } from '../models/IPoint';
import { IPointInfo } from '../models/IPointInfo';
import { PointInfoComponent } from './pointInfo/PointInfoComponent';

interface RouteParams{
  lang?:string;
  space?:string;
  city?: string;
}

const MainPageComponent =  ({ match }: RouteComponentProps<RouteParams>) => {
  const history = useHistory();
  const createdHistory = createHistory();//our created custom history for not re-rendering when going into details of page

  const [productCategoriesState, setProductCategoriesState] = useState<ProductCategory[]>([])
  const [isLoadingState, setIsLoadingState] = useState(false)

  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const [showNewsPopup, setShowNewsPopup] = useState(false);
  const [languagesState, setLanguagesState] = useState<Language[]>([]);
  const [currentNewsState, setCurrentNewsState] = useState<News | undefined>(undefined);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState<Product | undefined>(undefined)
  const [pointInfo, setPointInfo] = useState<IPointInfo | undefined>(undefined);

  //check is closed
  useEffect(() => {
    if(!match.url.includes('/map')) {
      var isChecked = window.sessionStorage.getItem(`is-checked-${match.params?.lang}`);
      if(!isChecked) {
        window.sessionStorage.setItem(`is-checked-${match.params?.lang}`, `checked-${match.params?.lang}`);
        ApiManager.getInstance().getData<IPointInfo>(`client/${match.params?.lang}/check-is-open?branchName=${match.params.space === "map" ? undefined : match.params.space}`)
        .then((data) => {
          if(!data?.open) {
            setPointInfo(data)
          }
        })
      }
    }
  }, [match.params.space, match.params.lang])

  //coordinates latitude and longitude
  const [userCoordinates, setUserCoordinates] = useState<{ lat: number | null , lng: number | null }>({ lat: null, lng: null });
  //cities  
  const [cities, setCities] = useState<ICity[]>([]);
  const [selectedCity, setSelectedCity] = useState<ICity | undefined>(undefined);
  const [pointsByCity, setPointsByCity] = useState<IPoint[]>([]);
  //map
  const [showMapPage, setShowMapPage] = useState<boolean>(match.url.includes('/map'));

  //detect user back
  useEffect(() => {
    setShowMapPage(match.url.includes('/map'));
  }, [match.url.includes('/map')])

  useEffect(() => {
    if(match.params.space && !showMapPage) {
      ApiManager.getInstance().getData<string>(`client/default-branch-name?branchName=${match.params.space}`)
      .then(o => {
        if(match.params.space !== o) {
          createdHistory.push(`/${match.params?.lang}/${o?.toLocaleLowerCase()}`)
        }
      })
    }
  }, [match.params.space, showMapPage])

  //requser location access and get latitude and longitude
  useEffect(() => {
    if (navigator.geolocation && showMapPage) {
      const success = (position: GeolocationPosition) => {
        setUserCoordinates({ 
          lat: position.coords.latitude, 
          lng: position.coords.longitude 
        });
      }

      const error = async () => {
        var city = await ApiManager.getInstance().getData<ICity[]>(`client/${match.params?.lang}/cities`);
        var mainCity = city?.find(o => o.main);
        if(mainCity) {
          setUserCoordinates({ 
            lat: parseFloat(mainCity.latitude), 
            lng: parseFloat(mainCity.longitude) 
          });
        }
      }

      navigator.geolocation.getCurrentPosition(success, error);
    }
  }, [navigator.geolocation, showMapPage])

  //get points by city name
  useEffect(() => {
    ApiManager.getInstance().getData<IPoint[]>(`/client/${match.params?.lang}/points?&latitude=${userCoordinates!?.lat!}&longitude=${userCoordinates!?.lng!}`)
    .then(o => {
      setPointsByCity(o!)

      if(o!?.length === 0 && match.url!?.includes("/map")) {
        history.push(`/${match.params?.lang}`)
        document.getElementsByTagName('body')[0].classList.remove("visible-product-detail");
      }
    })
  }, [userCoordinates])

  useEffect(() => {
    ApiManager.getInstance().getData<ICity[]>(`client/${match.params?.lang}/cities`)
    .then(o => {
      if(match.url!?.includes("/map")) {
        setSelectedCity(o?.find(i => i.id === parseInt(match!?.params!?.city!)))
      }
      setCities(o!)
    })
  }, [history.location.pathname])

  //Open map
  const onCityItemClick = (city: ICity | undefined) => {
    if(city === undefined) {
      if(match.params.space === 'map') createdHistory.push(`/${match.params?.lang}/map`)
      else if(match.params.space) createdHistory.push(`/${match.params?.lang}/${match.params.space}/map`)
      else createdHistory.push(`/${match.params?.lang}/map`)
    }
    else {
      if(match.params.space === 'map') createdHistory.push(`/${match.params?.lang}/${match.params.space}/${city?.id}`)
      else if (match.params.space) createdHistory.push(`/${match.params?.lang}/${match.params.space}/map/${city?.id}`)
      else createdHistory.push(`/${match.params?.lang}/map/${city?.id}`)
    }
    setSelectedCity(city);
    setShowMapPage(true);
  }

  //close map
  const closeMapClick = () => {
    if(match.params.space === 'map') createdHistory.push(`/${match.params?.lang}`)
    else if (match.params.space)  createdHistory.push(`/${match.params?.lang}/${match.params.space}`)
    else createdHistory.push(`/${match.params?.lang}`)

    setSelectedCity(undefined);
    setShowMapPage(false);
  }

  const selectCityFromMap = (city: ICity | undefined) => {
    setSelectedCity(city);
    onCityItemClick(city);
  }


  //get products useeffect
  //within this useffect if we dont have languages  it will go out
  //whenever language state changes this will change as well
  useEffect(() => {

    if(languagesState.length==0){
      return;
    }

    const currentSpace = match.params.space;

    setIsLoadingState(true);

    // `client/${match.params?.lang}/${match.params.space}`
    ApiManager.getInstance().getData<ProductCategory[]>(`client/${match.params?.lang}?branchName=${match.params.space? match.params.space:""}`).then(dataResult=>{
      if(dataResult){
        dataResult.forEach(o=>{
          if(o.subCategories && o.subCategories.length>0){
            o.subCategories[0].isSelected=true;
          }
        });

        var allproductsImages:string[] = [];

        dataResult.forEach(o=>{

          if(o.products && o.products.length>0){
            var imgUrls = o.products.map(p=>p.mainPhoto);
            allproductsImages = allproductsImages.concat(imgUrls);
          }

          if(o.subCategories && o.subCategories.length>0){

            var resultImages = o.subCategories.flatMap(fm=>fm.products).map(fmp=>fmp?.mainPhoto).filter(fmp=>fmp!=undefined);
            
            resultImages.forEach(resultImage=>{

              if(resultImage){
                allproductsImages.push(resultImage);
              }
            })
            
          }

        });
        

         Promise.all(allproductsImages.map(o=>loadImage(o!)))
        .then(o=>{
         
        })
        .finally(()=>{
          setProductCategoriesState(dataResult);
          setIsLoadingState(false);
          setTimeout(function() {

            document.getElementsByTagName('body')[0].classList.add('loaded');
          }, 100);
        })
        
      }
    });
   
  }, [languagesState])

  const loadImage = (imageUrl:string) :Promise<HTMLImageElement>=> {
    return new Promise((resolve, reject) => {
      const loadImg = new Image()
      loadImg.src = imageUrl
      
      loadImg.onload = () =>{  
        resolve(loadImg)
        };
        
      loadImg.onerror = err =>{ 
        
        reject(err);
       }
    })
  }

  //get news useeffect
  useEffect(() => {

    if(languagesState.length==0){
      return;
    }

    ApiManager.getInstance().getData<News>(`client/${languagesState?.find(o=>o.isSelected)?.code?.toLowerCase()}/news`).then(dataResult=>{

      if(dataResult){
        setCurrentNewsState(dataResult);
      }
    });
   
  }, [languagesState])

  //get languages useeffect
  useEffect(() => {	

    if(history.location.pathname.includes("product")){
      return;
    }

    ApiManager.getInstance().getData<Language[]>("client/languages").then(dataResult=>{	

      if(dataResult){	

        const setMainLanguage=()=>{
          var mainLanguage = dataResult.find(o=>o.main==true);
          if(mainLanguage){
            mainLanguage.isSelected=true;
          }

          //own created history wont work because it didnt have updated match
          //we must use history to actually "redirect"
          history.push(Utils.ConstructUrl([mainLanguage?.code.toLowerCase(),match.params.space]));
        }

        if(match.params.lang){
          var currLanguageByCode = dataResult.find(o=>o.code?.toLowerCase()==match.params.lang?.toLowerCase());
          if(currLanguageByCode) {
            currLanguageByCode.isSelected=true;
          
          }
          else{
            setMainLanguage();
          }
        }
        else{
          setMainLanguage();
        }

        setLanguagesState( dataResult);	

      }	
    });	

  }, [history.location.pathname])

  useEffect(() => {
    setCurrentSelectedProduct(undefined);
    
  }, [history.location.pathname])

  const closeNewsPopup = () => {
    document.querySelector(".news-popup-component")?.classList.remove("visible");
    document.getElementsByTagName('body')[0].classList.remove("disable-scroll");
    setTimeout(() => {
      setShowNewsPopup(false);
    }, 800);
  }
  
  const productClicked= (product:Product)=>{

    const constructedUrl = Utils.ConstructUrl([match.params.lang,match.params.space,"product",product?.slug?.toString()]);
   
    setCurrentSelectedProduct(product);
   
    createdHistory.push(constructedUrl);
    //window.history.replaceState(null,product?.name ?? "",constructedUrl); 
  };

  const backClicked= ()=>{

    document.getElementsByTagName('body')[0].classList.remove("visible-product-detail");
    document.querySelector(".product-details-component")?.classList.remove("visible");

    setTimeout(() => {  
      setCurrentSelectedProduct(undefined);
    }, 800);

    const constructedUrl = Utils.ConstructUrl([match.params.lang,match.params.space])

    createdHistory.push(constructedUrl);
    //window.history.replaceState(null,"Meama Collect",constructedUrl);
    //history.push(constructedUrl);
  };

  useEffect(() => {
    
    if(createdHistory.location.pathname.includes("product")==false){

      setCurrentSelectedProduct(undefined);
    }

    if(!showMapPage)
    document.getElementsByTagName('body')[0].classList.remove("visible-product-detail");
    
  }, [createdHistory.location.pathname])

  return (
    <React.Fragment>

      {
        !pointInfo?.open && pointInfo !== undefined ?
        <PointInfoComponent showPopup={!pointInfo?.open && pointInfo !== undefined} pointInfo={pointInfo!} onClick={() => setPointInfo(undefined)} />
        : null
      }

      { !showMapPage && <HeaderComponent languages={languagesState} languageClicked={() => setShowLanguagePopup(true)} newsClicked={() => setShowNewsPopup(true)} currentNews={currentNewsState} /> }
      
      {
        isLoadingState ? (<ShimmerComponent />): (
        productCategoriesState?.map(o=>
          <ProductsSectionComponent  productClicked={productClicked} typeClass={o.sortIndex==0? "first" :""}  key={o.id} productCategory={o} />
        ))
      }

      { !isLoadingState && cities.length !== 0 && <CityListComponent cities={cities} onItemClick={onCityItemClick}/> }
      { showMapPage && <GoogleMapComponent userLocaton={userCoordinates} showMapPage={showMapPage} selectedCity={selectedCity} cities={cities} selectCityFromMap={selectCityFromMap} points={pointsByCity} languages={languagesState} closeMapClick={closeMapClick} /> }
      { showNewsPopup && currentNewsState ? (<NewsPopupComponent news={currentNewsState} closedClick={closeNewsPopup} />) : (<></>) }
      { currentSelectedProduct ? (<ProductDetailsComponent space={match.params.space} lang={match.params.lang} backClicked={backClicked} currentProduct={currentSelectedProduct} langauges={languagesState} />):(<></>) }

       {!Utils.IsWebView() && <FooterComponent language={match.params.lang!} />} 
    </React.Fragment>
  );
}

export default withRouter(MainPageComponent);