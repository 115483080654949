import React, { useEffect, useState } from 'react';

// CSS
import './LangItemComponent.css';

// Images
import checkmarkIconSrc from '../../assets/img/checkmark-icon.svg'
import georgianFlagSrc from '../../assets/img/georgian-flag.svg'
import englishFlagSrc from '../../assets/img/english-flag.svg'
import russianFlagSrc from '../../assets/img/russian-flag.svg'
import { Language } from '../../models/Language';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Utils } from '../../utils/Utils';
import i18next from 'i18next';

interface LangItemComponentProps{
  language:Language
}

// interface RouterParams{
//   lang?:string,
//   space?:string
// }

const LangItemComponent:React.FC<LangItemComponentProps> = ({language}) => {
  
  const history = useHistory();
  const location = useLocation();
  const elementClicked = ()=>{

    
    var splited = window.location.pathname.split("/").filter(o=>Utils.isStringNullOrEmpty(o)==false);
    if(splited){

      if(splited[0]==language.code?.toLowerCase()){//if its current language code
        return;
      }

      splited[0]=language.code.toLowerCase();
      var result  = splited.join("/");
      
      
      history.push("/"+result);
      history.go(0);
    }

  };

  return (
    <React.Fragment>
      <div onClick={o=>elementClicked()}  className={"lang-item d-flex align-items-center justify-content-between "+(language.isSelected ?"active":"")}>
        <div className="d-flex align-items-center">
          <img className="flag-icon" src={language.imageUrl} alt="" />
          <p>{language.name}</p>
        </div>
        <div>
          <div className="checkmark-icon">
            <img src={checkmarkIconSrc} alt="" />
          </div>
        </div>
      </div>
     
     
    </React.Fragment>
  );
}

export default LangItemComponent;