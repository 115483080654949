import React, { useState , useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'

// Components
import ProductsHeadlineComponent from '../productsHeadline/ProductsHeadlineComponent';
import ProductItemComponent from '../productItem/ProductItemComponent';

// CSS
import './ProductsSectionComponent.css';
import { ProductCategory } from '../../models/ProductCategory';
import { Product } from '../../models/Product';

interface ProductsSectionProps{
  productCategory:ProductCategory,
  typeClass?:string,
  productClicked?:(product:Product)=>void;
}

const ProductsSectionComponent:React.FC<ProductsSectionProps> = ({productCategory, typeClass ,productClicked}) => {

  const [productCategoryState, setProductCategoryState] = useState<ProductCategory>(productCategory)

  const [currentProducts, setCurrentProducts] = useState<Product[] | null>([])
  
  const [allDefaultProducts, setAllDefaultProducts] = useState<Product[] | null>(null)

  useEffect(() => {
    
      if(allDefaultProducts==null){

        var tempProducts:Product[] = [];

        tempProducts = tempProducts.concat(productCategory?.products?? []);

        productCategory.subCategories?.forEach(o=>{
          
          o.products?.forEach(p=>{

            if(tempProducts.find(f=>f.id==p.id)==undefined){
              tempProducts.push(p);
            }
          })
        })

        setAllDefaultProducts(tempProducts);

        // var allProducts = getCurrentProductsBySection();
        // if(allProducts){
        //   setAllDefaultProducts(allProducts);
        // }
      }
    
  }, [])

  const getCurrentProductsBySection = ()=>{

    if(productCategoryState.products?.length==0 && productCategoryState.subCategories?.length!=0){

      var selectedByCategories = productCategoryState.subCategories?.find(o=>o.isSelected);

      if(selectedByCategories){
        return selectedByCategories.products;
      }
    }

    return productCategoryState.products;

  };

  const subCategoryclicked=(productCategory:ProductCategory)=>{

    if(productCategoryState.subCategories?.length==0 ){
      return;
    }

    if(productCategoryState.subCategories?.find(o=>o.isSelected)!.name==productCategory.name){
      return;
    }

    if(productCategoryState.subCategories){

      var copiedData = [...productCategoryState.subCategories];
      copiedData[copiedData.indexOf(copiedData.find(o=>o.isSelected)!)].isSelected=false;

      copiedData[copiedData.indexOf( copiedData.find(o=>o.name==productCategory.name)!)].isSelected=true;

      setProductCategoryState({...productCategoryState,subCategories:copiedData});

      var selectedProds = copiedData.find(o=>o.isSelected)?.products
      
      allDefaultProducts?.forEach(o=>{

        var currentHtmlElement = document.querySelector(".prodItem-"+o.id)
        
        currentHtmlElement?.classList.remove("show");

      });

      selectedProds?.forEach(o=>{
        setTimeout(() => {
          var currentHtmlElement = document.querySelector(".prodItem-"+o.id)
          currentHtmlElement?.classList.add("show");
        }, 600);
      });
    }
    
  };
  

  return (
    <div className={"products-section-component"+" "+typeClass}>
      <div className="container">
        <ProductsHeadlineComponent key={productCategoryState.id} categoryClicked={subCategoryclicked} productCategory={productCategoryState} />
        <ScrollContainer className="scroller d-flex flex-nowrap">
            {allDefaultProducts?.map(o=>(
              <ProductItemComponent productClicked={productClicked} key={o.id} categoryType={productCategoryState.categoryType} product={o} />
            ))}
            <div className="white-space"></div>
        </ScrollContainer>
      </div>
    </div>
  );
  
}

export default ProductsSectionComponent;